import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import work from './blocks/work.js';
import mobileNav from './alpinejs/mobile-nav.js';
import body from './alpinejs/body.js';
import { testimonials } from './blocks/testimonials.js';

/**
 * Application entrypoint
 */
domReady(async () => {
    Alpine.plugin(collapse)
    window.Alpine = Alpine;
    document.addEventListener('alpine:init', () => {
        Alpine.data('work', work);
        Alpine.data('mobileNav', mobileNav);
        Alpine.data('body', body);
    });
    Alpine.start();

    testimonials();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
