export default () => ({
    category: 0,
    posts_per_page: 0,
    ajax: 0,
    fetching: false,
    animationClass: ['opacity-0'],
    init() {
        this.posts_per_page = this.$el.dataset.postsPerPage;
        this.ajax = this.$el.dataset.ajax;
        this.$watch('category', (value) => {
            if (!this.fetching) {
                this.filter();
            }
        });
    },
    async filter() {
        this.fetching = true;
        const data = {
            action: 'work_filter',
            posts_per_page: this.posts_per_page,
            category: this.category,
            nonce: ajax_object.nonce,
        };

        fetch(ajax_object.ajaxurl + '?action=work_filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((json) => {
                let container = document.getElementById('work-container');
                this.animator(container).then(() => {
                    this.rePopulator(container, json.work).then(() => {
                        this.fetching = false;
                    });
                });
            });
    },
    async animator(container) {
        const delay = 50;

        let work = container.querySelectorAll('.work');
        work = [...work].reverse();

        for (let i = 0; i < work.length; i++) {
            work[i].classList.add(...this.animationClass);
            console.log('beep');
            await this.sleeper(delay);
        }

        container.innerHTML = '';
        // await this.sleeper(delay);
    },
    async rePopulator(container, items) {
        const delay = 50;

        if (items) {
            for (var i = 0; i < items.length; i++) {
                container.insertAdjacentHTML('beforeend', items[i]);
            }

            let work = container.querySelectorAll('.work');
            for (let i = 0; i < work.length; i++) {
                work[i].classList.remove(...this.animationClass);
                await this.sleeper(delay);
            }
        }
    },
    sleeper(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    },
});
