import Swiper, { Autoplay } from 'swiper';

export function initTestimonials() {
    if (window.acf) {
        window.acf.addAction(
            'render_block_preview/type=testimonials',
            testimonials
        );
    }
}

export function testimonials() {
    let testimonial_blocks = document.querySelectorAll('.testimonial-swiper');
    if (!testimonial_blocks.length) return false;

    [...testimonial_blocks].forEach((v, i, a) => {
        new Swiper(v, {
            modules: [Autoplay],
            autoplay: {
                delay: 3000,
            },
            loop: true,
        });
    });
}
